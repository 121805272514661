
.content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 20px;

    // background-color: rgb(170, 37, 37);
    .main {
        width: 100%;
        height: 80vh;
        box-sizing: border-box;
        padding: 30px;
        background-color: #fff;
    }
}

.header {
    display: flex;
    justify-content: space-between;
}

.tip {
    width: 445px;
    height: 17px;
    font-weight: 500;
    font-size: 14px;
    color: #666666;
    line-height: 40px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}
